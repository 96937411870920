// Global Registration page
// Register components or directives here to use anywhere in the vue project

// Imports vue into the project
import Vue from  'vue';

const BasePage = () => import(/* webpackChunkName: "basePage" */ '@/components/layout/BasePage');
const BaseHeader = () => import(/* webpackChunkName: "baseHeader" */ '@/components/layout/BaseHeader');
const BaseBody = () => import(/* webpackChunkName: "baseBody" */ '@/components/layout/BaseBody');
const BaseFooter = () => import(/* webpackChunkName: "baseFooter" */ '@/components/layout/BaseFooter');
const BaseSection = () => import(/* webpackChunkName: "baseSection" */ '@/components/layout/BaseSection');
const BaseAccordion = () => import(/* webpackChunkName: "baseAccordion" */ '@/components/layout/BaseAccordion');
const BaseCard = () => import(/* webpackChunkName: "baseCard" */ '@/components/layout/BaseCard');
const BaseCardSection = () => import(/* webpackChunkName: "baseCardSection" */ '@/components/layout/BaseCardSection');
const BaseGrid = () => import(/* webpackChunkName: "baseGrid" */ '@/components/layout/BaseGrid');
const BaseGridContainer = () => import(/* webpackChunkName: "baseGridContainer" */ '@/components/layout/BaseGridContainer');
const BaseSidebar = () => import(/* webpackChunkName: "baseSidebar" */ '@/components/layout/BaseSidebar');
const BaseStack = () => import(/* webpackChunkName: "baseStack" */ '@/components/layout/BaseStack');
const BaseNavigation = () => import(/* webpackChunkName: "baseNavigation" */ '@/components/layout/BaseNavigation');
const BaseNavigationGroup = () => import(/* webpackChunkName: "baseNavigationGroup" */ '@/components/layout/BaseNavigationGroup');
const BaseTable = () => import(/* webpackChunkName: "baseTable" */ '@/components/layout/BaseTable');
const BaseTableRow = () => import(/* webpackChunkName: "baseTableRow" */ '@/components/layout/BaseTableRow');
const BaseTableCell = () => import(/* webpackChunkName: "baseTableCell" */ '@/components/layout/BaseTableCell');

const BaseParagraph = () => import(/* webpackChunkName: "baseParagraph" */ '@/components/foundation/typography/BaseParagraph');
const BaseHeading = () => import(/* webpackChunkName: "baseHeading" */ '@/components/foundation/typography/BaseHeading');
const BaseQuote = () => import(/* webpackChunkName: "baseQuote" */ '@/components/foundation/typography/BaseQuote');
const BaseDivider = () => import(/* webpackChunkName: "baseDivider" */ '@/components/foundation/BaseDivider');

const BaseAvatar = () => import(/* webpackChunkName: "baseAvatar" */ '@/components/element/BaseAvatar');
const BaseButton = () => import(/* webpackChunkName: "baseButton" */ '@/components/element/BaseButton');

const BaseForm = () => import(/* webpackChunkName: "baseForm" */ '@/components/form/BaseForm');
const BaseFormContainer = () => import(/* webpackChunkName: "baseFormContainer" */ '@/components/form/BaseFormContainer');
const BaseInput = () => import(/* webpackChunkName: "baseInput" */ '@/components/form/BaseInput');
const BaseTextarea = () => import(/* webpackChunkName: "baseTextarea" */ '@/components/form/BaseTextarea');

const BaseImage = () => import(/* webpackChunkName: "baseImage" */ '@/components/media/BaseImage');
const BaseLogo = () => import(/* webpackChunkName: "baseLogo" */ '@/components/media/BaseLogo');
const BaseSlider = () => import(/* webpackChunkName: "baseSlider" */ '@/components/media/BaseSlider');
const BaseSliderContainer = () => import(/* webpackChunkName: "baseSliderContainer" */ '@/components/media/BaseSliderContainer');
const BaseIcon = () => import(/* webpackChunkName: "baseIcon" */ '@/components/media/BaseIcon');

const BaseLink = () => import(/* webpackChunkName: "baseLink" */ '@/components/navigation/BaseLink');

const BaseDropdown = () => import(/* webpackChunkName: "baseDropdown" */ '@/components/overlay/BaseDropdown');
const BaseDrawer = () => import(/* webpackChunkName: "baseDrawer" */ '@/components/overlay/BaseDrawer');
const BaseModal = () => import(/* webpackChunkName: "baseModal" */ '@/components/overlay/BaseModal');


// Anna Robbins Project Specific
const BaseColor = () => import(/* webpackChunkName: "baseColor" */ '@/components/anna-robbins/BaseColor');
const BaseLaptop = () => import(/* webpackChunkName: "baseLaptop" */ '@/components/anna-robbins/BaseLaptop');
const BaseBrowser = () => import(/* webpackChunkName: "baseBrowser" */ '@/components/anna-robbins/BaseBrowser');
const BasePhone = () => import(/* webpackChunkName: "basePhone" */ '@/components/anna-robbins/BasePhone');

// Design System Project Specific
const BaseTheme = () => import(/* webpackChunkName: "baseTheme" */ '@/components/design-system/BaseTheme');
const BaseStage = () => import(/* webpackChunkName: "baseStage" */ '@/components/design-system/BaseStage');


Vue.component('Page', BasePage);
Vue.component('Header', BaseHeader);
Vue.component('Body', BaseBody);
Vue.component('Footer', BaseFooter);
Vue.component('Section', BaseSection);
Vue.component('Accordion', BaseAccordion);
Vue.component('Card', BaseCard);
Vue.component('CardSection', BaseCardSection);
Vue.component('Grid', BaseGrid);
Vue.component('GridContainer', BaseGridContainer);
Vue.component('Sidebar', BaseSidebar);
Vue.component('Stack', BaseStack);
Vue.component('Navigation', BaseNavigation);
Vue.component('NavigationGroup', BaseNavigationGroup);
Vue.component('Table', BaseTable);
Vue.component('TableRow', BaseTableRow);
Vue.component('TableCell', BaseTableCell);

Vue.component('Paragraph', BaseParagraph);
Vue.component('Heading', BaseHeading);
Vue.component('Quote', BaseQuote);
Vue.component('Divider', BaseDivider);

Vue.component('Avatar', BaseAvatar);
Vue.component('Button', BaseButton);

Vue.component('Form', BaseForm);
Vue.component('FormContainer', BaseFormContainer);
Vue.component('Input', BaseInput);
Vue.component('Textarea', BaseTextarea);

Vue.component('Images', BaseImage);
Vue.component('Logo', BaseLogo);
Vue.component('Slider', BaseSlider);
Vue.component('SliderContainer', BaseSliderContainer);
Vue.component('Icon', BaseIcon);

Vue.component('Link', BaseLink);

Vue.component('Dropdown', BaseDropdown);
Vue.component('Drawer', BaseDrawer);
Vue.component('Modal', BaseModal);

Vue.component('Color', BaseColor);
Vue.component('Laptop', BaseLaptop);
Vue.component('Browser', BaseBrowser);
Vue.component('Phone', BasePhone);

Vue.component('Theme', BaseTheme);
Vue.component('Stage', BaseStage);




