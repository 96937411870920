// Imports vue into the project
import Vue from 'vue'

// Global click outside directive
// Selects - when a user clicks outside of the select, the select drop down will close
Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and its children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
});

Vue.mixin({
	methods: {
		stageTheme: function(id) {
			
			id = localStorage.getItem("theme");
			var stageElement = document.querySelectorAll('.stage');
			var themes = ['theme-ds','theme-ar','theme-sd','theme-wm','theme-dl','theme-ed','theme-hv'];

			for (var i=0; i < stageElement.length; i++) {
				for (var j=0; j < themes.length; j++) {
					if(id === themes[j]){
						stageElement[i].setAttribute("theme", themes[j]);
						localStorage.setItem("theme", themes[j]);
					}
				}
			}
			var themeElement = document.querySelectorAll('.theme');
			for (var a=0; a < themeElement.length; a++) {
				for (var b=0; b < themes.length; b++) {
					if(id === themes[b]){
						themeElement[a].setAttribute("stage", themes[b]);
					}
				}
			}
		},
		classNames: function(item) {
			let id = this.id;
			let div = document.getElementById(id);
			let name = item.name;
			let prop = item.prop;
			let j = this.$props[prop];

			if (j !== undefined) {
				if (prop === "visibility") {
					if (j.indexOf(" ") >= 0) {
						var s1 = j.split(" ")[0];
						var s2 = j.split(" ")[1];
						var s3 = j.split(" ")[2];
						var s4 = j.split(" ")[3];

						var s5 = s1.substr(0, s1.indexOf("@"));
						var s6 = s1.substr(s1.indexOf("@") + 1);

						div.classList.add(name + "--" + s5 + "-" + s6);

						if (s2) {
							var s7 = s2.substr(0, s2.indexOf("@"));
							var s8 = s2.substr(s2.indexOf("@") + 1);
							div.classList.add(name + "--" + s7 + "-" + s8);
						}
						if (s3) {
							var s9 = s3.substr(0, s3.indexOf("@"));
							var s0 = s3.substr(s3.indexOf("@") + 1);
							div.classList.add(name + "--" + s9 + "-" + s0);
						}
						if (s4) {
							var s10 = s4.substr(0, s4.indexOf("@"));
							var s11 = s4.substr(s4.indexOf("@") + 1);
							div.classList.add(name + "--" + s10 + "-" + s11);
						}
					} else {
						var a5 = j.substr(0, j.indexOf("@"));
						var a6 = j.substr(j.indexOf("@") + 1);
						div.classList.add(name + "--" + a5 + "-" + a6);
					}
				} else {
					if (j.indexOf(" ") >= 0) {
						var c1 = j.split(" ")[0];
						var c2 = j.split(" ")[1];
						var c3 = j.split(" ")[2];
						var c4 = j.split(" ")[3];

						var c5 = c2.substr(0, c2.indexOf("@"));
						var c6 = c2.substr(c2.indexOf("@") + 1);

						div.classList.add(name + "--" + prop + "-" + c1);
						div.classList.add(name + "--" + prop + "-" + c6 + "-" + c5);

						if (c3) {
							var c7 = c3.substr(0, c3.indexOf("@"));
							var c8 = c3.substr(c3.indexOf("@") + 1);
							div.classList.add(name + "--" + prop + "-" + c8 + "-" + c7);
						}
						if (c4) {
							var c9 = c4.substr(0, c4.indexOf("@"));
							var c0 = c4.substr(c4.indexOf("@") + 1);
							div.classList.add(name + "--" + prop + "-" + c0 + "-" + c9);
						}
					}
				}
			}
		},
	}
});
