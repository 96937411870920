<template>
	<div id="app">
		<div class="theme-selector" v-click-outside="setClose">
			<Stack justify="spaceBetween" @click.native="setOpen()">
				<Stack space="2">
					<Stack>
						<Stage>
							<Avatar text="AR" v-if="theme === 'theme-ar'" />
							<Avatar text="DS" v-if="theme === 'theme-ds'" />
							<Avatar text="DL" v-if="theme === 'theme-dl'" />
							<Avatar text="ED" v-if="theme === 'theme-ed'" />
							<Avatar text="HV" v-if="theme === 'theme-hv'" />
							<Avatar text="SD" v-if="theme === 'theme-sd'" />
							<Avatar text="WM" v-if="theme === 'theme-wm'" />
						</Stage>
					</Stack>
					<Stack visibility="hide show@sm">
						<Paragraph whiteout bold margin="none" v-if="theme === 'theme-ar'">Anna Robbins</Paragraph>
						<Paragraph whiteout bold margin="none" v-if="theme === 'theme-ds'">Design System</Paragraph>
						<Paragraph whiteout bold margin="none" v-if="theme === 'theme-dl'">Dallas</Paragraph>
						<Paragraph whiteout bold margin="none" v-if="theme === 'theme-ed'">Edison</Paragraph>
						<Paragraph whiteout bold margin="none" v-if="theme === 'theme-hv'">Havelock</Paragraph>
						<Paragraph whiteout bold margin="none" v-if="theme === 'theme-sd'">Sedona</Paragraph>
						<Paragraph whiteout bold margin="none" v-if="theme === 'theme-wm'">Windermere</Paragraph>
					</Stack>
				</Stack>
				<Icon icon="expand_more" v-if="!isOpen" color="light" />
				<Icon icon="expand_less" v-if="isOpen" color="light" />
			</Stack>
			<Dropdown :open="isOpen">
				<Stack direction="column">
					<Button label="Anna Robbins" color="light" type="soft" full @click.native="setTheme('theme-ar')" />
					<Button label="Dallas" color="light" type="soft" full @click.native="setTheme('theme-dl')" />
					<Button label="Edison" color="light" type="soft" full @click.native="setTheme('theme-ed')" />
					<Button label="Havelock" color="light" type="soft" full @click.native="setTheme('theme-hv')" />
					<Button label="Sedona" color="light" type="soft" full @click.native="setTheme('theme-sd')" />
					<Button label="Windermere" color="light" type="soft" full @click.native="setTheme('theme-wm')" />
					<Button label="Design System" color="light" type="soft" full @click.native="setTheme('theme-ds')" />
					<Button label="More about themes" color="light" url="/component/theme" />
				</Stack>
			</Dropdown>
		</div>

		<router-view />
	</div>
</template>

<script> 
export default {
  data() {
    return {
      theme: 'theme-ds',
			isOpen: false,
		}
  },
  mounted() {
    let bodyElement = document.documentElement;
    bodyElement.setAttribute('theme', 'theme-ds');
		localStorage.setItem("theme", 'theme-ds');
  },
  watch: {
    theme() {
			var stageElement = document.querySelectorAll('.stage');
			for (var i=0; i < stageElement.length; i++) {
				if (this.theme === 'theme-ds') {
					stageElement[i].setAttribute("theme", "theme-ds");
					localStorage.setItem("theme", 'theme-ds');
				} else if (this.theme === 'theme-sd') {
					stageElement[i].setAttribute("theme", "theme-sd");
					localStorage.setItem("theme", 'theme-sd');
				} else if (this.theme === 'theme-ar') {
					stageElement[i].setAttribute("theme", "theme-ar");
					localStorage.setItem("theme", 'theme-ar');
				} else if (this.theme === 'theme-ed') {
					stageElement[i].setAttribute("theme", "theme-ed");
					localStorage.setItem("theme", 'theme-ed');
				} else if (this.theme === 'theme-hv') {
					stageElement[i].setAttribute("theme", "theme-hv");
					localStorage.setItem("theme", 'theme-hv');
				} else if (this.theme === 'theme-wm') {
					stageElement[i].setAttribute("theme", "theme-wm");
					localStorage.setItem("theme", 'theme-wm');
				} else if (this.theme === 'theme-dl') {
					stageElement[i].setAttribute("theme", "theme-dl");
					localStorage.setItem("theme", 'theme-dl');
				}
			}
			var themeElement = document.querySelectorAll('.theme');
			for (var a=0; a < themeElement.length; a++) {
				if (this.theme === 'theme-ds') {
					themeElement[a].setAttribute("stage", "theme-ds");
				} else if (this.theme === 'theme-sd') {
					themeElement[a].setAttribute("stage", "theme-sd");
				} else if (this.theme === 'theme-ar') {
					themeElement[a].setAttribute("stage", "theme-ar");
				} else if (this.theme === 'theme-ed') {
					themeElement[a].setAttribute("stage", "theme-ed");
				} else if (this.theme === 'theme-hv') {
					themeElement[a].setAttribute("stage", "theme-hv");
				} else if (this.theme === 'theme-wm') {
					themeElement[a].setAttribute("stage", "theme-wm");
				} else if (this.theme === 'theme-dl') {
					themeElement[a].setAttribute("stage", "theme-dl");
				}
			}
    },
		'$route' (to) {
			document.title = to.meta.title || 'Anna Robbins'
		}
  },
	methods: {
		setTheme(e){
			this.theme = e
		},
		setOpen(){
			this.isOpen = !this.isOpen;
		},
    setClose() {
      this.isOpen = false
    },
	}
}
</script>

<style lang="scss">
@import '@/assets/scss/theme-ar/imports.scss';
@import '@/assets/scss/theme-ed/imports.scss';
@import '@/assets/scss/theme-hv/imports.scss';
@import '@/assets/scss/theme-sd/imports.scss';
@import '@/assets/scss/theme-ds/imports.scss';
@import '@/assets/scss/theme-wm/imports.scss';
@import '@/assets/scss/theme-dl/imports.scss';

@import '@/assets/scss/breakpoints.scss';

body{
	margin:var(--body-mobile-margin);
	background: var(--body-background);
	@include breakpoint(sm){
		margin:var(--body-tablet-margin);
	}
	@include breakpoint(md){
		margin:var(--body-margin);
	}
}
.theme-selector{
	display: var(--theme-switch-display);
	position: fixed;
	right: 24px;
	top: 0;
	width: 60px;
	z-index: 10;
	border-left: 1px solid rgba(255,255,255,0.1);
	border-right: 1px solid rgba(255,255,255,0.1);
	background: rgba(255,255,255,.04);
	padding: 17px 12px;
	cursor: pointer;
	@include breakpoint(sm){
		width: 190px;
	}
}
</style>