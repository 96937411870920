import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{ path: '/', name: 'Home', component: () => import('@/views/annarobbins/Home'), props: { theme: 'theme-ar' }, meta: { auth: true, title: 'Anna Robbins' }},
	{ path: '/about', name: 'About', component: () => import('@/views/annarobbins/About'), props: { theme: 'theme-ar' }, meta: { auth: true, title: 'Anna Robbins - About' }},
	{ path: '/contact', name: 'Contact', component: () => import('@/views/annarobbins/Contact'), props: { theme: 'theme-ar' }, meta: { auth: true, title: 'Anna Robbins - Contact' }},
	{ path: '/portfolio/dallas', name: 'Portfolio - Dallas', component: () => import('@/views/annarobbins/portfolio/Dallas'), props: { theme: 'theme-ar' }, meta: { auth: true, title: 'Anna Robbins - Dallas' }},
	{ path: '/portfolio/edison', name: 'Portfolio - Edison', component: () => import('@/views/annarobbins/portfolio/Edison'), props: { theme: 'theme-ar' }, meta: { auth: true, title: 'Anna Robbins - Edison' }},
	{ path: '/portfolio/havelock', name: 'Portfolio - Havelock', component: () => import('@/views/annarobbins/portfolio/Havelock'), props: { theme: 'theme-ar' }, meta: { auth: true, title: 'Anna Robbins - Havelock' }},
	{ path: '/portfolio/sedona', name: 'Portfolio - Sedona', component: () => import('@/views/annarobbins/portfolio/Sedona'), props: { theme: 'theme-ar' }, meta: { auth: true, title: 'Anna Robbins - Sedona' }},
	{ path: '/portfolio/windermere', name: 'Portfolio - Windermere', component: () => import('@/views/annarobbins/portfolio/Windermere'), props: { theme: 'theme-ar' }, meta: { auth: true, title: 'Anna Robbins - Windermere' }},
	
	{ path: '/design-system', name: 'Design System', component: () => import('@/views/designsystem/Home'), props: { theme: 'theme-ds' }, meta: { auth: true, title: 'Axiom Design System' }},
	{ path: '/component/accordion', name: 'Design System - Components - Accordion', component: () => import('@/views/designsystem/components/Accordion'), props: { theme: 'theme-ds' }, meta: { auth: true, title: 'Axiom Design System - Accordion' }},
	{ path: '/component/button', name: 'Design System - Components - Button', component: () => import('@/views/designsystem/components/Button'), props: { theme: 'theme-ds' }, meta: { auth: true, title: 'Axiom Design System - Button' }},
	{ path: '/component/card', name: 'Design System - Components - Card', component: () => import('@/views/designsystem/components/Card'), props: { theme: 'theme-ds' }, meta: { auth: true, title: 'Axiom Design System - Card' }},
	{ path: '/component/color', name: 'Design System - Components - Color', component: () => import('@/views/designsystem/components/Color'), props: { theme: 'theme-ds' }, meta: { auth: true, title: 'Axiom Design System - Color' }},
	{ path: '/component/form', name: 'Design System - Components - Form', component: () => import('@/views/designsystem/components/Form'), props: { theme: 'theme-ds' }, meta: { auth: true, title: 'Axiom Design System - Form' }},
	{ path: '/component/grid', name: 'Design System - Components - Grid', component: () => import('@/views/designsystem/components/Grid'), props: { theme: 'theme-ds' }, meta: { auth: true, title: 'Axiom Design System - Grid' }},
	{ path: '/component/icon', name: 'Design System - Components - Icon', component: () => import('@/views/designsystem/components/Icon'), props: { theme: 'theme-ds' }, meta: { auth: true, title: 'Axiom Design System - Icon' }},
	{ path: '/component/image', name: 'Design System - Components - Image', component: () => import('@/views/designsystem/components/Image'), props: { theme: 'theme-ds' }, meta: { auth: true, title: 'Axiom Design System - Image' }},
	{ path: '/component/link', name: 'Design System - Components - Link', component: () => import('@/views/designsystem/components/Link'), props: { theme: 'theme-ds' }, meta: { auth: true, title: 'Axiom Design System - Link' }},
	{ path: '/component/page', name: 'Design System - Components - Page', component: () => import('@/views/designsystem/components/Page'), props: { theme: 'theme-ds' }, meta: { auth: true, title: 'Axiom Design System - Page' }},
	{ path: '/component/stack', name: 'Design System - Components - Stack', component: () => import('@/views/designsystem/components/Stack'), props: { theme: 'theme-ds' }, meta: { auth: true, title: 'Axiom Design System - Stack' }},
	{ path: '/component/theme', name: 'Design System - Components - Theme', component: () => import('@/views/designsystem/components/Theme'), props: { theme: 'theme-ds' }, meta: { auth: true, title: 'Axiom Design System - Theme' }},
	{ path: '/component/typography', name: 'Design System - Components - Typography', component: () => import('@/views/designsystem/components/Typography'), props: { theme: 'theme-ds' }, meta: { auth: true, title: 'Axiom Design System - Typography' }},

	{ path: '/edison', name: 'Edison', component: () => import('@/views/projects/edison/Home'), props: { theme: 'theme-ed' }, meta: { auth: true, title: 'Edison Digital' }},
	{ path: '/edison/company', name: 'Edison - Company', component: () => import('@/views/projects/edison/Company'), props: { theme: 'theme-ed' }, meta: { auth: true, title: 'Edison Digital - Company' }},
	{ path: '/edison/services', name: 'Edison - Services', component: () => import('@/views/projects/edison/Services'), props: { theme: 'theme-ed' }, meta: { auth: true, title: 'Edison Digital - Services' }},
	{ path: '/edison/blog', name: 'Edison - Blog', component: () => import('@/views/projects/edison/Blog'), props: { theme: 'theme-ed' }, meta: { auth: true, title: 'Edison Digital - Blog' }},
	{ path: '/edison/contact', name: 'Edison - Contact', component: () => import('@/views/projects/edison/Contact'), props: { theme: 'theme-ed' }, meta: { auth: true, title: 'Edison Digital - Contact' }},

	{ path: '/havelock', name: 'Havelock', component: () => import('@/views/projects/havelock/Home'), props: { theme: 'theme-hv' }, meta: { auth: true, title: 'Carter Havelock' }},

	{ path: '/sedona', name: 'Sedona', component: () => import('@/views/projects/sedona/Home'), props: { theme: 'theme-sd' }, meta: { auth: true, title: 'Sedona Day Spa' }},
	{ path: '/sedona/about', name: 'Sedona - About', component: () => import('@/views/projects/sedona/About'), props: { theme: 'theme-sd' }, meta: { auth: true, title: 'Sedona Day Spa - About' }},
	{ path: '/sedona/treatments', name: 'Sedona - Treatments', component: () => import('@/views/projects/sedona/Treatments'), props: { theme: 'theme-sd' }, meta: { auth: true, title: 'Sedona Day Spa - Treatments' }},
	{ path: '/sedona/treatments/treatment', name: 'Sedona - Treatment', component: () => import('@/views/projects/sedona/Treatment'), props: { theme: 'theme-sd' }, meta: { auth: true, title: 'Sedona Day Spa - Treatment' }},
	{ path: '/sedona/services', name: 'Sedona - Services', component: () => import('@/views/projects/sedona/Services'), props: { theme: 'theme-sd' }, meta: { auth: true, title: 'Sedona Day Spa - Services' }},
	{ path: '/sedona/blog', name: 'Sedona - Blog', component: () => import('@/views/projects/sedona/Blog'), props: { theme: 'theme-sd' }, meta: { auth: true, title: 'Sedona Day Spa - Blog' }},
	{ path: '/sedona/blog/post', name: 'Sedona - Blog - Post', component: () => import('@/views/projects/sedona/Post'), props: { theme: 'theme-sd' }, meta: { auth: true, title: 'Sedona Day Spa - Post' }},
	{ path: '/sedona/contact', name: 'Sedona - Contact', component: () => import('@/views/projects/sedona/Contact'), props: { theme: 'theme-sd' }, meta: { auth: true, title: 'Sedona Day Spa - Contact' }},

	{ path: '/windermere', name: 'Windermere', component: () => import('@/views/projects/windermere/Home'), props: { theme: 'theme-wm' }, meta: { auth: true, title: 'Windermere' }},
	{ path: '/windermere/company', name: 'Windermere - Company', component: () => import('@/views/projects/windermere/Company'), props: { theme: 'theme-wm' }, meta: { auth: true, title: 'Windermere - Company' }},
	{ path: '/windermere/services', name: 'Windermere - Services', component: () => import('@/views/projects/windermere/Services'), props: { theme: 'theme-wm' }, meta: { auth: true, title: 'Windermere - Services' }},
	{ path: '/windermere/blog', name: 'Windermere - Blog', component: () => import('@/views/projects/windermere/Blog'), props: { theme: 'theme-wm' }, meta: { auth: true, title: 'Windermere - Blog' }},
	{ path: '/windermere/post', name: 'Windermere - Post', component: () => import('@/views/projects/windermere/Post'), props: { theme: 'theme-wm' }, meta: { auth: true, title: 'Windermere - Post' }},
	{ path: '/windermere/contact', name: 'Windermere - Contact', component: () => import('@/views/projects/windermere/Contact'), props: { theme: 'theme-wm' }, meta: { auth: true, title: 'Windermere - Contact' }},
	
	{ path: '/dallas', name: 'Dallas', component: () => import('@/views/projects/dallas/Home'), props: { theme: 'theme-dl' }, meta: { auth: true, title: 'Dallas Art Academy' }},


]

const router = new VueRouter({
  routes: routes,
  mode: 'history',
  linkExactActiveClass: 'is-active',
})

export default router
