// Imports vue into the project
import Vue from 'vue'
import App from './App.vue'
import router from './router'

// Import global components
import './components/components.js'

// Import design system global functions
import './assets/js/functions'

// Disables the production mode warning "You are running Vue in development mode."
Vue.config.productionTip = false

// Runs the Vue Project in the #app div in public/index.html
// and enables the router links
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')